// src/redux/dialerSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  visible: false,
  callNumber: null,
};

const dialerSlice = createSlice({
  name: 'dialer',
  initialState,
  reducers: {
    toggleVisibility: (state, action) => {
      // Verifica se o valor do payload foi passado, caso contrario inverte o estado
      state.visible = action.payload !== undefined ? action.payload : !state.visible;
    },
    setCallNumber: (state, action) => {
      state.callNumber = action.payload;  
    },
  },
});

export const { toggleVisibility, setCallNumber } = dialerSlice.actions;

export default dialerSlice.reducer;
