import React, { useState, useEffect } from "react";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";

// Importando o Provider do Redux e o Store
import { Provider } from 'react-redux';
import store from './redux/store';  // Certifique-se de que o caminho está correto

// Verificar se o store está corretamente importado
console.log(store); // Verifique se o store é um objeto válido

const App = () => {
  const [locale, setLocale] = useState();

  const theme = createTheme(
    {
      scrollbarStyles: {
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          backgroundColor: "#606060",
        },
      },
      palette: {
        primary: { main: "#044842" },
      },
    },
    locale
  );

  useEffect(() => {
    const i18nlocale = localStorage.getItem("i18nextLng");
    const browserLocale =
      i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

    if (browserLocale === "ptBR") {
      setLocale(ptBR);
    }
  }, []);

  return (
    <Provider store={store}>  {/* Envolvendo o aplicativo com o Redux Provider */}
      <ThemeProvider theme={theme}>
        <Routes />  {/* Suas rotas e componentes vão poder acessar o estado do Redux */}
      </ThemeProvider>
    </Provider>
  );
};

export default App;
