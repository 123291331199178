import React, { useState, useEffect, useReducer, useContext } from "react";
import openSocket from "../../services/socket-io";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";

import TicketListItem from "../TicketListItem";
import TicketsListSkeleton from "../TicketsListSkeleton";

import useTickets from "../../hooks/useTickets";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import Globais from "../../services/globais";

const useStyles = makeStyles((theme) => ({
  ticketsListWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  ticketsList: {
    flex: 1,
    overflowY: "scroll",
    ...theme.scrollbarStyles,
    borderTop: "2px solid rgba(0, 0, 0, 0.12)",
  },

  ticketsListHeader: {
    color: "rgb(67, 83, 105)",
    zIndex: 2,
    backgroundColor: "white",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  ticketsCount: {
    fontWeight: "normal",
    color: "rgb(104, 121, 146)",
    marginLeft: "8px",
    fontSize: "14px",
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const isTicketFavoriteForUser = (ticket, userId) => {
  const result = ticket.userTicketFavorites.some(
    (fav) => fav.userId === userId && fav.ticketId === ticket.id
  );
  return result;
};

const reducer = (state, action) => {
  // Isso resolve o problema da barra de pesquisa.
  // Precisa ser testado, pois foi removido o trecho abaixo para ser adicioando esse
  //if (action.type === "LOAD_TICKETS") {
  //  return action.payload;
  // }

  // Antes estava assim, comentei e adicione o o trecho de cima
  // Voltei para esse estado, estava tendo problemas com a scrollbar a forma de cima

  /*
  if (action.type === "LOAD_TICKETS") {
    const newTickets = action.payload;
    console.log("Dados do Novo Ticket Recebido: ", newTickets);

    newTickets.forEach((ticket) => {
      const ticketIndex = state.findIndex((t) => t.id === ticket.id);
      if (ticketIndex !== -1) {
        state[ticketIndex] = ticket;
        if (ticket.unreadMessages > 0) {
          state.unshift(state.splice(ticketIndex, 1)[0]);
        }
      } else {
        state.push(ticket);
      }
    });

    return [...state];
  } */



  if (action.type === "LOAD_TICKETS") {
    const newTickets = action.payload;
      const user = action.user;
    console.log("Dados do Novo Ticket Recebido: ", newTickets);

    newTickets.forEach((ticket) => {
      const ticketIndex = state.findIndex((t) => t.id === ticket.id);
      const isFav = isTicketFavoriteForUser(ticket, user.id); // Aqui você calcula se o ticket é favorito ou não

      if (ticketIndex !== -1) {
        // Ticket já existe no estado, então atualiza
        state[ticketIndex] = ticket;

        if (ticket.unreadMessages > 0) {
          if (isFav) {
            // Verifique se é favorito usando a variável isFav
            state.unshift(state.splice(ticketIndex, 1)[0]);
          } else {
            const favTickets = state.filter((t) =>
              isTicketFavoriteForUser(t, user.id)
            ); // Recalcula a favoritação
            const nonFavTickets = state.filter(
              (t) => !isTicketFavoriteForUser(t, user.id) && t.id !== ticket.id
            ); // Recalcula a favoritação
            state = [...favTickets, ticket, ...nonFavTickets];
          }
        }
      } else {
        // Ticket não existe, então adiciona novo
        if (ticket.unreadMessages > 0) {
          if (isFav) {
            state.unshift(ticket);
          } else {
            const favTickets = state.filter((t) =>
              isTicketFavoriteForUser(t, user.id)
            );
            const nonFavTickets = state.filter(
              (t) => !isTicketFavoriteForUser(t, user.id) && t.id !== ticket.id
            );
            state = [...favTickets, ticket, ...nonFavTickets];
          }
        } else {
          state.push(ticket);
        }
      }
    });

    return [...state];
  }

  if (action.type === "RESET_UNREAD") {
    const ticketId = action.payload;
    console.log("Dados do RESET_UNREAD Ticket Recebido: ", ticketId);
    const ticketIndex = state.findIndex((t) => t.id === ticketId);
    if (ticketIndex !== -1) {
      state[ticketIndex].unreadMessages = 0;
    }

    return [...state];
  }

  if (action.type === "RESET_TRANSFER") {
    const ticketId = action.payload;
    console.log("Dados do RESET_TRANSFER Ticket Recebido: ", ticketId);
    const ticketIndex = state.findIndex((t) => t.id === ticketId);
    if (ticketIndex !== -1) {
      state[ticketIndex].isTransferred = false;
    }

    return [...state];
  }

  

if (action.type === "UPDATE_TICKET") {
    const ticket = action.payload;
    console.log("Dados do Update Ticket Recebido: ", ticket);
    const user = action.user;

    const ticketIndex = state.findIndex((t) => t.id === ticket.id);

    const isFav = ticket.userTicketFavorites.some(
      (fav) => fav.userId === user.id && fav.ticketId === ticket.id
    );

    if (ticketIndex !== -1) {
      const existingTicket = state[ticketIndex];

      state[ticketIndex] = ticket;

      if (
        existingTicket.userTicketFavorites.some(
          (fav) => fav.userId === user.id && fav.ticketId === ticket.id
        ) &&
        !isFav
      ) {
        const favTickets = state.filter((t) =>
          t.userTicketFavorites.some(
            (fav) => fav.userId === user.id && fav.ticketId === t.id
          )
        );
        const nonFavTickets = state.filter(
          (t) =>
            !t.userTicketFavorites.some(
              (fav) => fav.userId === user.id && fav.ticketId === t.id
            )
        );


        const updatedState = favTickets.concat(nonFavTickets);

        return updatedState; // Retorna o novo estado atualizado
      }

      // Se o ticket foi marcado como favorito
      if (isFav && ticketIndex !== 0) {
        state.unshift(state.splice(ticketIndex, 1)[0]);
      }
    } else {
      // Se o ticket não existir, adiciona ele
      const favTickets = state.filter((t) =>
        t.userTicketFavorites.some(
          (fav) => fav.userId === user.id && fav.ticketId === t.id
        )
      );
      const nonFavTickets = state.filter(
        (t) =>
          !t.userTicketFavorites.some(
            (fav) => fav.userId === user.id && fav.ticketId === t.id
          )
      );

      if (isFav) {
        state.unshift(ticket);
      } else {
        state = [...favTickets, ticket, ...nonFavTickets];
      }
    }


    return [...state];
   
} 




 

  if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
    const ticket = action.payload;
      const user = action.user;
    console.log(
      "Dados do UPDATE_TICKET_UNREAD_MESSAGES Ticket Recebido: ",
      ticket
    );

    const ticketIndex = state.findIndex((t) => t.id === ticket.id);

    const isFav = ticket.userTicketFavorites.some(
      (fav) => fav.userId === user.id && fav.ticketId === ticket.id
    );

    if (ticketIndex !== -1) {
      const existingTicket = state[ticketIndex];


      state[ticketIndex] = ticket;

      
      if (
        existingTicket.userTicketFavorites.some(
          (fav) => fav.userId === user.id && fav.ticketId === ticket.id
        ) &&
        !isFav
      ) {
        const favTickets = state.filter((t) =>
          t.userTicketFavorites.some(
            (fav) => fav.userId === user.id && fav.ticketId === t.id
          )
        );
        const nonFavTickets = state.filter(
          (t) =>
            !t.userTicketFavorites.some(
              (fav) => fav.userId === user.id && fav.ticketId === t.id
            )
        );

        const updatedState = favTickets.concat(nonFavTickets);

        return updatedState; 
      }

      if (isFav && ticketIndex !== 0) {
        state.unshift(state.splice(ticketIndex, 1)[0]);
      }
    } else {

      const favTickets = state.filter((t) =>
        t.userTicketFavorites.some(
          (fav) => fav.userId === user.id && fav.ticketId === t.id
        )
      );
      const nonFavTickets = state.filter(
        (t) =>
          !t.userTicketFavorites.some(
            (fav) => fav.userId === user.id && fav.ticketId === t.id
          )
      );

      if (isFav) {
        state.unshift(ticket);
      } else {
        state = [...favTickets, ticket, ...nonFavTickets];
      }
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET_CONTACT") {
    const contact = action.payload;
    console.log("Dados do UPDATE_TICKET_CONTACT Ticket Recebido: ", contact);
    const ticketIndex = state.findIndex((t) => t.contactId === contact.id);
    if (ticketIndex !== -1) {
      state[ticketIndex].contact = contact;
    }
    return [...state];
  }

  if (action.type === "DELETE_TICKET") {
    const ticketId = action.payload;
    console.log("Dados do DELETE_TICKET Ticket Recebido: ", ticketId);
    const ticketIndex = state.findIndex((t) => t.id === ticketId);
    if (ticketIndex !== -1) {
      state.splice(ticketIndex, 1);
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const TicketsList = (props) => {
  const {
    status,
    searchParam,
    showAll,
    selectedQueueIds,
    updateCount,
    style,
    tags,
  } = props;
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(1);
  const [ticketsList, dispatch] = useReducer(reducer, []);
  const { user } = useContext(AuthContext);
  const { profile, queues } = user;

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [status, searchParam, dispatch, showAll, selectedQueueIds, tags]);

  const { tickets, hasMore, loading } = useTickets({
    pageNumber,
    searchParam,
    status,
    showAll,
    tags: JSON.stringify(tags),
    queueIds: JSON.stringify(selectedQueueIds),
  });

  useEffect(() => {
    const queueIds = queues.map((q) => q.id);
    //const filteredTickets = tickets.filter((t) => queueIds.indexOf(t.queueId) > -1);

    const filteredTickets = tickets.filter((t) => {
      const isAdmin = user.profile.toUpperCase() == "ADMIN";
      const isTouserMatch = t.touserId == user.id;
      const isUserIdMatch = t.userId == user.id;
      if (Number(t.touserId) > 0) {
        return isAdmin || isTouserMatch || isUserIdMatch;
      } else {
        return queueIds.indexOf(t.queueId) > -1;
      }
    });

    if (profile === "user") {
      dispatch({ type: "LOAD_TICKETS", payload: filteredTickets, user });
      Globais.searchParam = searchParam;
    } else {
      dispatch({ type: "LOAD_TICKETS", payload: tickets, user });
      Globais.searchParam = searchParam;
    }
  }, [tickets, status, searchParam, queues, profile]);

  useEffect(() => {
    const socket = openSocket();

    const shouldUpdateTicket = (ticket) =>
      (!ticket.userId || ticket.userId === user?.id || showAll) &&
      (!ticket.queueId || selectedQueueIds.indexOf(ticket.queueId) > -1);

    const notBelongsToUserQueues = (ticket) =>
      ticket.queueId && selectedQueueIds.indexOf(ticket.queueId) === -1;

    const belongsToUserQueues = (ticket) => {
      return user.queues.some(
        (user) => user.UserQueue.queueId === ticket.queueId
      )
        ? true
        : false;
    };

    socket.on("connect", () => {
      if (status) {
        socket.emit("joinTickets", status);
      } else {
        socket.emit("joinNotification");
      }
    });

    socket.on("ticket", (data) => {
      if (data.action === "updateUnread") {
        dispatch({
          type: "RESET_UNREAD",
          payload: data.ticketId,
        });
      }

      if (data.action === "updateTransfer") {
        dispatch({
          type: "RESET_TRANSFER",
          payload: data.ticketId,
        });
      }

      if (
        data.action === "update" &&
        (shouldUpdateTicket(data.ticket) || belongsToUserQueues(data.ticket) ||
          user.profile.toUpperCase() == "ADMIN")
      ) {
        dispatch({
          type: "UPDATE_TICKET",
          payload: data.ticket,
          user
        });
      }

      if (data.action === "update" && notBelongsToUserQueues(data.ticket)) {
        dispatch({ type: "DELETE_TICKET", payload: data.ticket.id });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_TICKET", payload: data.ticketId });
      }
    });

    socket.on("appMessage", (data) => {
      if (data.ticket.status == "open" && data.ticket.touserId) {
        if (
          data.ticket.touserId == user.id ||
          user.profile.toUpperCase() == "ADMIN" ||
          belongsToUserQueues(data.ticket)
        ) {
          dispatch({
            type: "UPDATE_TICKET_UNREAD_MESSAGES",
            payload: data.ticket,
            user
          });
          dispatch({
            type: "UPDATE_TICKET",
            payload: data.ticket,
          });
        }
      }

      if (
        data.action === "create" &&
        (shouldUpdateTicket(data.ticket) ||
          user.profile.toUpperCase() == "ADMIN") ||
          belongsToUserQueues(data.ticket)
      ) {

        if (
          user.profile.toUpperCase() !== "ADMIN" &&
          data.ticket.status == "pending" &&
          data.ticket.queueId == null
        ) {
        } else {
          dispatch({
            type: "UPDATE_TICKET_UNREAD_MESSAGES",
            payload: data.ticket,
            user
          });
        }
      }
    });

    socket.on("contact", (data) => {
      if (data.action === "update") {
        dispatch({
          type: "UPDATE_TICKET_CONTACT",
          payload: data.contact,
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [status, showAll, user, selectedQueueIds]);

  useEffect(() => {
    if (typeof updateCount === "function") {
      updateCount(ticketsList.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketsList]);

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;

    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <Paper className={classes.ticketsListWrapper} style={style}>
      <Paper
        square
        name="closed"
        elevation={0}
        className={classes.ticketsList}
        onScroll={handleScroll}
      >
        <List style={{ paddingTop: 0 }}>
          {ticketsList.length === 0 && !loading ? (
            <div className={classes.noTicketsDiv}>
              <span className={classes.noTicketsTitle}>
                {i18n.t("ticketsList.noTicketsTitle")}
              </span>
              <p className={classes.noTicketsText}>
                {i18n.t("ticketsList.noTicketsMessage")}
              </p>
            </div>
          ) : (
            <>
              {ticketsList.map((ticket) => (
                <TicketListItem ticket={ticket} key={ticket.id} />
              ))}
            </>
          )}
          {loading && <TicketsListSkeleton />}
        </List>
      </Paper>
    </Paper>
  );
};

export default TicketsList;
